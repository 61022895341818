/**
 * @file Modal
 */
'use strict';

/**
 * @classname Modal
 * @classdesc Modal
 */
export default class Modal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Modal.constructor');
		this._$e = $e;
		this._target = $e.attr('data-target');
		this._$modal = $(this._target);
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof Modal
	 * @return {undefined}
	 */
	onClick() {
		console.log('Modal.onClick');
		this._$modal.addClass('show').show();
		$('body').css('overflow', 'hidden')
		// $('body').css('overflow', 'hidden')
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof Modal
	 * @return {undefined}
	 */
	hide() {
		console.log('Modal.hide');
		this._$modal.removeClass('show').hide();
		// $('body').css('overflow', 'initial')
	};

}
