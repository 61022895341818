'use strict'

// utility class
import ApplyClass from '../Utils/ApplyClass'

import Form from '../Controller/Form'

// 汎用的な機能
import Validate from '../View/Form/Validate'
import Submit from '../View/Form/Submit'
import ZenkakuToHankaku from '../View/Form/ZenkakuToHankaku'
import TogglePassword from '../View/Form/TogglePassword'

// 作った：プロジェクト固有の機能
import RadioClearBtn from '../View/Form/RadioClearBtn'
import DynamicUrlAndPath from '../View/Form/DynamicUrlAndPath'
import OnChangeRadioSubmit from '../View/Form/OnChangeRadioSubmit'
import ConsentDownloadValidate from '../View/Form/ConsentDownloadValidate'

$(() => {
	const FormController = new Form()
	// validate
	$('.validate').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(Validate, $e, [$e, FormController])
	})
	// 全角→半角変換
	$('.zen2han').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(ZenkakuToHankaku, $e, [$e, FormController])
	})
	// submit
	$('[type=submit][data-form]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(Submit, $e, [$e, FormController])
	})
	// パスワードの表示・非表示切り替え
	$('.btn-toggle-password').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(TogglePassword, $e, [$e, FormController])
	})
	// ラジオボックスの選択解除
	$('[data-clear-radio-container]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(RadioClearBtn, $e, [$e, FormController])
	})
	// {指定のURL}/{radioのvalue}に飛ばす
	$('.dynamic-url-link').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(DynamicUrlAndPath, $e, [$e, FormController])
	})
	// onchangeイベントでradioボタンをsubmitさせる
	$('.onchange-send-query-radio').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(OnChangeRadioSubmit, $e, [$e, FormController])
	})
	// 同意にチェックを入れないとダウンロードできないようにする
	$('.consent-download-valid').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(ConsentDownloadValidate, $e, [$e, FormController])
	})
})
