/**
 * @file Page TOPボタン
 */
'use strict'

/**
 * @classname GotoTopButton
 * @classdesc トップへ戻るボタン
 */
export default class GotoTopButton {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.anchor-go-to-top')
	 */
	constructor($e) {
		console.log('GotoTopButton.constructor')
		this._$e = $e
		this.onLoad()
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof GotoTopButton
	 * @return {undefined}
	 */
	onLoad() {
        $(window).on('scroll', () => {
			if ($(window).scrollTop() > 1200) {
				$(this._$e).removeClass('hidden')
            } else {
                $(this._$e).addClass('hidden')
			}
		})
	}
}
