/**
 * @file {指定のURL}/{radioのvalue}に飛ばす
 */
'use strict'

/**
 * @classname ConsentDownloadValidate
 * @classdesc {指定のURL}/{radioのvalue}に飛ばす
 */
export default class ConsentDownloadValidate {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.consent-download-valid')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$checkbox = $e.find('input[type="checkbox"]')
		this._$targets = $e.find('.disabled')
		this._$checkbox.on('change', () => this.onChange())
	}

	/**
	 * onChangeのコールバック
	 *
	 * @memberof ConsentDownloadValidate
	 * @return {undefined}
	 */
	onChange() {
		if (this._$checkbox.prop('checked')) {
			this._$targets.each((idx, target) => {
				$(target).removeClass('disabled')
			})
		} else {
			this._$targets.each((idx, target) => {
				$(target).addClass('disabled')
			})
		}
	}
}
