/**
 * @file FormModel Controller
 */

'use strict'
import Listener from '../Utils/Listener'
import Common from '../Utils/Common'

let instance = null

/**
 * @classname FormModel
 * @classdesc Basic Model
 */
export default class FormModel {
	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance
		}

		this._listener = new Listener()
		this._jqXHR = null
		this._post = false
		this._data = null
		this._message = null
		this._code = null

		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			},
		})

		instance = this
		return instance
	}

	/**
	 * listener on
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn)
	}

	/**
	 * listner off
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn)
	}
}
