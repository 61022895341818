import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
/**
 * @file 下部のナビバー
 */
;('use strict')

/**
 * @classname OrthodonticHorizontalScroll
 * @classdesc voiceを全て横スクロールしないと下に行けない制御
 */
export default class OrthodonticHorizontalScroll {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.list-top-voice__container')
	 */
	constructor($e) {
		console.log('OrthodonticHorizontalScroll.constructor', $e)
		this._$e = $e
		this._$scrollItem = $e.find('.list')
		// this._$body = $('body')

		console.log('dev', this._$scrollItem.width(), $e.width())
		gsap.registerPlugin(ScrollTrigger)
		gsap.to(this._$scrollItem, {
			x: () => -(this._$scrollItem.width() - $e.width()),
			ease: 'none',
			scrollTrigger: {
				trigger: '.orthodontic-horizontal-scroll',
				// trigger: '.block-top-voice__container',
				start: 'top top', // 要素の上端（top）が、ビューポートの上端（top）にきた時
				end: () => `+=${this._$scrollItem.width() - $e.width()}`,
				scrub: true,
				pin: true,
				anticipatePin: 1,
				invalidateOnRefresh: true,
			},
		})
	}
	triggerFullHeight() {
$('.orthodontic-horizontal-scroll')
	}
}
