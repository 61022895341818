/**
 * @file Form Controller
 */

'use strict'
import Listener from '../Utils/Listener'
import FormModel from '../Model/FormModel'

let instance = null

/**
 * @classname Form
 * @classdesc Form Controller
 */
export default class Form {
	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance
		}
		console.log('Form.constructor')

		this._model = new FormModel()

		this._listener = new Listener()
		this._$form = null
		this._submit = true

		this._model.on('uploadPhotoComplete', () => {
			this._imageData = this._model._data
			this._listener.trigger('uploadPhotoComplete')
		})
		this._model.on('deletePhotoComplete', () => {
			this._listener.trigger('deletePhotoComplete')
		})

		instance = this
		return instance
	}

	/**
	 * 前処理終了
	 *
	 * 同ページ内で複数formに .check-all を使用したい場合は
	 * 前処理終了時にこちらの trigger を引く
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completeMultipleCheckAllPreProcessing($form) {
		console.log('Form.completeMultipleCheckAllPreProcessing')
		this._$form = $form
		this._listener.trigger('completeMultipleCheckAllPreProcessing')
	}

	/**
	 * 前処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePreProcessing($form) {
		console.log('Form.completePreProcessing')
		this._$form = $form
		this._listener.trigger('completePreProcessing')
	}

	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validate($form) {
		console.log('Form.validate')
		this._$form = $form
		this._listener.trigger('validate')
		setTimeout(() => {
			this._listener.trigger('completeValidate')
		}, 500)
	}

	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validateModal($form) {
		console.log('Form.validate')
		this._$form = $form
		this._listener.trigger('validate')
	}

	/**
	 * 後処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePostProcessing($form) {
		console.log('Form.completePostProcessing')
		this._$form = $form
		this._listener.trigger('completePostProcessing')
	}

	/**
	 * 写真アップロード
	 *
	 * @memberof Form
	 * @param {blob} file - 画像ファイル
	 * @param {integer} userId - ユーザーID
	 * @return {undefined}
	 */
	uploadPhoto(file, userId) {
		console.log('Form.deletePhoto')
		this._model.uploadPhoto(file, userId)
	}

	/**
	 * 掲示板HTML作成
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	createHtml(flg) {
		console.log('Form.createHtml')
		if (flg) {
			this._listener.trigger('onCreateDataSubmit')
		} else {
			this._listener.trigger('onCreateData')
		}
	}

	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $datepicker - $datepicker object
	 * @return {undefined}
	 */
	clearSelectedDate($datepicker) {
		this._$datepicker = $datepicker
		this._listener.trigger('clearSelectedDate')
	}

	/**
	 * listener on
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn)
	}

	/**
	 * listener off
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn)
	}
}
