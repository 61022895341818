/**
 * @file タブ
 */
'use strict'

/**
 * @classname Tab
 * @classdesc タブ
 */
export default class Tab {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Tab.constructor')
		this._$e = $e
		this._$tabs = $e.find('.tab')
		this._$panels = $e.find('.panel')
		this._$tabs.each((idx, tab) => {
			$(tab).on('click', () => this.onClick(idx, tab))
		})
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof Tab
	 * @return {undefined}
	 */
	onClick(index, tab) {
		this._$tabs.each((idx, tab) => {
			$(tab).removeClass('active')
			$(tab).attr('aria-selected', false)
		})
		this._$panels.each((idx, panel) => {
			$(panel).removeClass('active')
		})
		$(tab).addClass('active')
		$(tab).attr('aria-selected', true)
		$(this._$panels[index]).addClass('active')
	}
}
