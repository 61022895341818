/**
 * @file 下部のナビバー
 */
'use strict'

/**
 * @classname BottomNavigation
 * @classdesc SPの下部メニュー
 */
export default class BottomNavigation {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.list-bottom-navigation')
	 */
	constructor($e) {
		console.log('BottomNavigation.constructor')
		this._$e = $e
		$(window).on('scroll', () => this.onScroll())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof BottomNavigation
	 * @return {undefined}
	 */
	onScroll() {
		const _$footerPosition = $('footer').offset().top
		if(_$footerPosition < $(window).scrollTop()){
			$(this._$e).addClass('hidden')
		}else {
			$(this._$e).removeClass('hidden')

		}
	}
}
