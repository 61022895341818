/**
 * @file SlideShow
 */
'use strict'

/**
 * @classname SlideShow
 * @classdesc SlideShow
 */
export default class SlideShow {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$slides = $e.find('img')

		setInterval(this.slideShow.bind(this), 8000)
	}

	slideShow() {
		const current = this._$e.find('img.current')
		const next = current.next().length ? current.next() : this._$slides.first()
		current.removeClass('current');
		next.addClass('current');
	}
}
