/**
 * @file Page TOPボタン
 */
'use strict'

/**
 * @classname LogoColorChange
 * @classdesc 緑背景と被った時にロゴの色変更
 */
export default class LogoColorChange {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.image-sidebar__logo')
	 */
	constructor($e) {
		console.log('LogoColorChange.constructor')
		this._$e = $e
		$(window).on('scroll', () => this.onScroll())
	}

	/**
	 * clickのコールバック
	 *
	 * @memberof LogoColorChange
	 * @return {undefined}
	 */
	onScroll() {
		if ($('.logo-color-change__start').length && $('.logo-color-change__end').length) {
			const logoHeight = Math.round($(this._$e).offset().top)
			const startHeight = Math.round($('.logo-color-change__start').offset().top) + 60
			const endHeight = Math.round($('.logo-color-change__end').offset().top) + 20

			if (startHeight > logoHeight) {
				$(this._$e).removeClass('white')
			} else if (startHeight < logoHeight && endHeight > logoHeight) {
				$(this._$e).addClass('white')
			} else {
				$(this._$e).removeClass('white')
			}
		}
	}
}
