/**
 * @file 下部のナビバー
 */
'use strict'

/**
 * @classname AutoPlayHorizontalScroll
 * @classdesc SPのスポンサー部分をオートプレイ
 */
export default class AutoPlayHorizontalScroll {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.list-top-support__sponsor__container')
	 */
	constructor($e) {
		console.log('AutoPlayHorizontalScroll.constructor', $e)
		this._$e = $e
		this.autoPlay()
	}
	autoPlay() {
		setInterval(() => {
			if (this.isElementHorizontallyScrolledToMax()) {
				this._$e.get(0).scrollLeft = 0
				return
			}
			this._$e.get(0).scrollLeft += 1
		}, 10)
	}

	isElementHorizontallyScrolledToMax() {
		const scrollableWidth = this._$e.get(0).scrollWidth - this._$e.width()
		const currentScrollPosition = this._$e.get(0).scrollLeft
		return scrollableWidth > 0 && currentScrollPosition === scrollableWidth
	}
}
