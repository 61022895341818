/**
 * @file topセレクタで矯正的に縦スクロールさせる
 */
'use strict'

/**
 * @classname stickyScrollableSideMenuWithSubMenu
 * @classdesc topセレクタで矯正的に縦スクロールさせる
 */
export default class stickyScrollableSideMenuWithSubMenu {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.sidebar__scroll-area')
	 */
	constructor($e) {
		console.log('stickyScrollableSideMenuWithSubMenu.constructor')
		this._$e = $e

		this._$item = $e.find('.sidebar__scroll-container')
		this.scrollPositionBeforeHover = 0
		this.isHovered = false
		this._$e.on('mouseenter', () => {
			console.log('mouseenter')
			this.isHovered = true
			this.scrollPositionBeforeHover = $(window).scrollTop()
			$('body').css('position', 'fixed')
			$('body').css('overflow-y', 'scroll')
			$('body').css('top', `${-this.scrollPositionBeforeHover}px`)
		})
		this._$e.on('mouseleave', () => {
			console.log('mouseleave', this.scrollPositionBeforeHover)
			this.isHovered = false
			$('body').css('position', 'static')
			$('body').css('overflow-y', 'initial')
			$(window).scrollTop(this.scrollPositionBeforeHover)
		})
		$(window).on('wheel', (e) => this.onWheel(e))
		if (jQuery(':hover').hasClass('sidebar')) {
			$('body').css('position', 'fixed')
			$('body').css('overflow-y', 'scroll')
			this.isHovered = true
		}
	}

	/**
	 * wheelのコールバック
	 *
	 * @memberof stickyScrollableSideMenuWithSubMenu
	 * @return {undefined}
	 */
	onWheel(e) {
		if (this.isHovered) {
			const screenHeight = $(window).height()
			const currentScrollPosition = $(window).scrollTop()
			const screenCenterScrollPosition = Math.round(currentScrollPosition + screenHeight / 4)
			const elementEnd = this._$item.offset().top + this._$item.height()

			if (this.extractAndConvertToNumber(this._$item.css('top')) + e.originalEvent.deltaY > 0) {
				// した方向のみスクロールを許可

				if (elementEnd - screenCenterScrollPosition > e.originalEvent.deltaY) {
					// メニューが画面下部の位置までスクロールが達していなかったら実行
					console.log(-this.extractAndConvertToNumber(this._$item.css('top')))
					console.log(this._$item.css('top'))
					this._$item.css('top', -(this.extractAndConvertToNumber(this._$item.css('top')) + e.originalEvent.deltaY))
				}
			} else {
				// 上方向にスクロールさせない
				this._$item.css('top', 0)
			}
		}
	}

	extractAndConvertToNumber(inputString) {
		const numericString = inputString.replace('px', '')
		const numericValue = Math.abs(Number(numericString))

		return numericValue
	}
}
