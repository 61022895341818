'use strict'

// utility class
import ApplyClass from '../Utils/ApplyClass'
import Common from '../Utils/Common'
import Basic from '../Controller/Basic'

import SmoothScroll from '../View/Basic/SmoothScroll'
import Tab from '../View/Basic/Tab'
import Modal from '../View/Basic/Modal'
import ModalClose from '../View/Basic/ModalClose'
import Accordion from '../View/Basic/Accordion'
import LayeredAccordion from '../View/Basic/LayeredAccordion'
import CloseAlert from '../View/Basic/CloseAlert'

// 作った : 汎用的ではない
import GotoTopButton from '../View/Basic/GotoTopButton'
import LogoColorChange from '../View/Basic/LogoColorChange'
import BottomNavigation from '../View/Basic/BottomNavigation'
// import CorrectionHorizontalScroll from '../View/Basic/CorrectionHorizontalScroll'
import AutoPlayHorizontalScroll from '../View/Basic/AutoPlayHorizontalScroll'
import stickyScrollableSideMenuWithSubMenu from '../View/Basic/stickyScrollableSideMenuWithSubMenu'
import HalfAccordion from '../View/Basic/HalfAccordion'
import ScrollHint from 'scroll-hint'
import FontSizeChangeButton from '../View/Basic/FontSizeChangeButton'
import OrthodonticHorizontalScroll from '../View/Basic/OrthodonticHorizontalScroll'
import SlideShow from '../View/Basic/SlideShow'

$(() => {
	const BasicController = new Basic()

	// デバイス、ブラウザ判定
	Common.addHtmlClass()
	// 文字サイズ変更ボタン
	$('.fontsize-change').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(FontSizeChangeButton, $e, [$e])
	})
	//スムーススクロール
	$('a[href^="#"]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(SmoothScroll, $e, [$e, BasicController])
	})
	// モーダル
	$('[data-modal=true]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(Modal, $e, [$e, BasicController])
	})
	// モーダル閉じる
	$('.modal [data-dismiss=modal]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(ModalClose, $e, [$e, BasicController])
	})
	// Alert閉じる
	$('.alert [data-dismiss=alert]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(CloseAlert, $e, [$e, BasicController])
	})
	// Tab
	$('.tab-container').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(Tab, $e, [$e, BasicController])
	})
	// Accordion
	$('[data-accordion-type="wrap"]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(Accordion, $e, [$e])
	})
	// LayeredAccordion
	$('[data-accordion-type="layered-wrap"]').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(LayeredAccordion, $e, [$e, BasicController])
	})
	// トップへ戻るボタン
	$('.anchor-go-to-top').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(GotoTopButton, $e, [$e])
	})
	// 緑背景と被った時にロゴの色変更
	$('.image-sidebar__logo').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(LogoColorChange, $e, [$e])
	})
	// SPの下部メニュー
	$('.list-bottom-navigation').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(BottomNavigation, $e, [$e])
	})
	// voiceを全て横スクロールしないと下に行けない制御
	$('.block-top-voice__container').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(OrthodonticHorizontalScroll, $e, [$e])
	})

	// $('.list-top-voice__container').each((idx, e) => {
	// 	const $e = $(e)
	// 	ApplyClass.apply(CorrectionHorizontalScroll, $e, [$e])
	// })
	// SPのスポンサー部分をオートプレイ
	$('.list-top-support__sponsor__container').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(AutoPlayHorizontalScroll, $e, [$e])
	})
	// サイドメニューを追従かつメニュー内スクロール可能かつサブメニューありにする
	$('.sidebar__scroll-area').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(stickyScrollableSideMenuWithSubMenu, $e, [$e])
	})
	// 半分閉じるアコーディオン
	$('.half-accordion').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(HalfAccordion, $e, [$e])
	})
	
	// スライドショー
	$('.slide-show').each((idx, e) => {
		const $e = $(e)
		ApplyClass.apply(SlideShow, $e, [$e])
	})


	new ScrollHint('.js-scrollable', {
		i18n: {
			scrollable: '',
		},
	})
})
