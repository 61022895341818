/**
 * @file FontSizeChangeButton
 */
'use strict'

/**
 * @classname FontSizeChangeButton
 * @classdesc 文字サイズ変更ボタン
 */
export default class FontSizeChangeButton {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$labels = $e.find('label')
		this._$radios = $e.find('input[type="radio"]')
		this._$allElements = $('body *')
		this.onMount()
		this._$radios.each((idx, _$radio) => {
			$(_$radio).on('change', () => this.onChange(idx, _$radio))
		})
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof FontSizeChangeButton
	 * @param {jQuery} _$radio - radioボタン
	 * @return {undefined}
	 */
	onChange(index, _$radio) {
		console.log(`${this.constructor.name}.onChange`)
		if ($(_$radio).prop('checked')) {
			this._$labels.each((idx, _$label) => {
				$(_$label).removeClass('current')
			})
			$(this._$labels[index]).addClass('current')
			const fontsize = $(_$radio).val()
			this.allElementNoneTransition() // 文字サイズ変更時にtransitionによってガタつくので全要素のtransitionを無効化
			$('html').css('font-size', fontsize)
			localStorage.setItem('saj-fontsize', fontsize)
			// 全要素のtransitionを元の値に戻す
			setTimeout(() => {
				this.inlineStyleTransitionDelete()
			}, 500)
		}
	}
	/**
	 * マウント時のコールバック
	 *
	 * @memberof FontSizeChangeButton
	 * @return {undefined}
	 */
	onMount() {
		const fontsize = localStorage.getItem('saj-fontsize')
		if (fontsize) {
			$('html').css('font-size', fontsize)
			this._$radios.each((idx, _$radio) => {
				if ($(_$radio).val() === fontsize) {
					$(_$radio).prop('checked', true)
					this._$labels.each((idx, _$label) => {
						$(_$label).removeClass('current')
					})
					$(this._$labels[idx]).addClass('current')
				}
			})
		} else {
			$(this._$labels[0]).addClass('current')
		}
	}
	// 全要素のtransitionを無効化
	allElementNoneTransition() {
		this._$allElements.each((idx, _$elm) => {
			$(_$elm).css('transition', 'all 0s ease 0s')
		})
	}
	// 全要素のインラインスタイルからtransitionを削除
	inlineStyleTransitionDelete() {
		this._$allElements.each((idx, _$elm) => {
			const transitionDeletedInlineStyle = $(_$elm).attr('style').replace('transition: all 0s ease 0s;', '')
			$(_$elm).attr('style', transitionDeletedInlineStyle)
		})
	}
}
