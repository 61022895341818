/**
 * @file パスワードの表示・非表示切り替え
 */
'use strict'

/**
 * @classname RadioClearBtn
 * @classdesc ラジオボックスの選択解除
 */
export default class RadioClearBtn {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('[data-clear-radio-container]')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$submitBtn = $(this._$e.data('submit-btn'))
		this._$radios = $(this._$e.data('clear-radio-container')).find('input[type="radio"]')
		this._$e.on('click', () => this.onClick())
	}

	/**
	 * onClick
	 *
	 * @memberof RadioClearBtn
	 * @return {undefined}
	 */
	onClick() {
		console.log(`${this.constructor.name}.onClick`)
		this._$radios.each((idx, _$radio) => {
			$(_$radio).prop('checked', false)
			if (this._$submitBtn.length) {
				this._$submitBtn.prop('disabled', true)
			}
		})
	}
}
