/**
 * @file onchangeイベントでradioボタンをsubmitさせる
 */
'use strict'

/**
 * @classname OnChangeRadioSubmit
 * @classdesc onchangeイベントでradioボタンをsubmitさせる
 */
export default class OnChangeRadioSubmit {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('onchange-send-query-radio')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this.radios = $e.find('input[type="radio"]')
		this.radios.each((idx, radio) => {
			this.changeColor($(radio));
			$(radio).on('change', () => this.onChange($(radio)))
		})
		this.currentUrl = new URL(window.location.href)
	}

	/**
	 * onChangeのコールバック
	 *
	 * @memberof OnChangeRadioSubmit
	 * @return {undefined}
	 */
	onChange(_$radio) {
		console.log('_$radio')
		const order = _$radio.val()
		this.changeColor(_$radio);
		if (_$radio.prop('checked')) {
			this.currentUrl.searchParams.set('order', order)
			this.currentUrl.searchParams.set('changed', 'true')
			window.location.href = this.currentUrl.toString()
		}
	}

	changeColor(_$radio) {
		if (_$radio.prop('checked')) {
			_$radio.parent().css('background','#115740');
			_$radio.next().css('color','#fff');
		} else {
			_$radio.parent().css('background','#ecf2f0');
			_$radio.next().css('color','#115740');
		}
	}
}
