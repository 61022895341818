/**
 * @file {指定のURL}/{radioのvalue}に飛ばす
 */
'use strict'

/**
 * @classname DynamicUrlAndPath
 * @classdesc {指定のURL}/{radioのvalue}に飛ばす
 */
export default class DynamicUrlAndPath {
	/**
	 * @constructor
	 * @param {jQuery} $e - $('.dynamic-url-link')
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this.targetUrl = window.const.URL[$e.data('link-url-env')]
		$e.on('submit', (e) => this.onSubmit(e))
	}

	/**
	 * onSubmitのコールバック
	 *
	 * @memberof DynamicUrlAndPath
	 * @return {undefined}
	 */
	onSubmit(e) {
		e.preventDefault()
		console.log(`${this.constructor.name}.onSubmit`)
		const path = this._$e.find('input[type="radio"]:checked').val()
		window.open(`${this.targetUrl}/${path}`, '_blank')
	}
}
