/**
 * @file HalfAccordion
 */
'use strict'

/**
 * @classname HalfAccordion
 * @classdesc 半分閉じるアコーディオン
 */
export default class HalfAccordion {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`)
		this._$e = $e
		this._$button = this._$e.find('.half-accordion__btn').first()
		this._$target = this._$e.find('.half-accordion__content').first()
		this.closeHeight = this._$target.height()
		this._$button.on('click', () => this.onClick())
		this.onClick()
		this._$button.remove()
		this._$target.style('height', 'auto')
	}

	/**
	 * クリックのコールバック
	 *
	 * @memberof HalfAccordion
	 * @return {undefined}
	 */
	onClick() {
		if (this._$button.hasClass('open')) {
			this._$button.removeClass('open').next().animate({ height: this.closeHeight }, 200)
		} else {
			const height = this._$target.children('div').first().outerHeight()
			console.log(height)
			this._$target.removeClass('close')

			this._$button.addClass('open').next().animate({ height: height }, 200) // 要素を初期の高さにアニメーションで戻す
		}
	}
}
