/**
 * @file FormBoard
 */
'use strict'

/**
 * @classname FormBoard
 * @classdesc
 */
export default class FormBoard {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller) {
		this._controller = controller
	}

	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormBoard
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log(`${this.constructor.name}.preProcessing`)
		this._controller.createHtml(true)
		$('[name=mode]').val('1')
		// バリデーション前に行う処理を記載（終了）
		this._controller.completePreProcessing($form)
	}

	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormBoard
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log(`${this.constructor.name}.postProcessing`)
		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form)
	}
}
