/**
 * @file Basic Controller
 */

'use strict'
import Listener from '../Utils/Listener'
import BasicModel from '../Model/BasicModel'
import Common from '../Utils/Common'

let instance = null

/**
 * @classname Basic
 * @classdesc Basic Controller
 */
export default class Basic {
	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance
		}
		console.log('Basic.constructor')

		this._model = new BasicModel()

		this._listener = new Listener()

		this._model.on('incrementReactionCountComplete', () => {
			this._incrementReactionCountResponse = this._model._data
			this._listener.trigger('incrementReactionCountComplete')
		})

		instance = this
		return instance
	}

	/**
	 * listener on
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn)
	}

	/**
	 * listener off
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn)
	}
}
