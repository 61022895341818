/**
 * @file FormModel Controller
 */

'use strict'
import Listener from '../Utils/Listener'
import Common from '../Utils/Common'

let instance = null

/**
 * @classname FormModel
 * @classdesc Basic Model
 */
export default class FormModel {
	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance
		}

		this._listener = new Listener()
		this._jqXHR = null
		this._post = false
		this._data = null
		this._message = null
		this._code = null
		this._detailPage = false

		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			},
		})

		instance = this
		return instance
	}

	/**
	 *
	 * 写真アップロード
	 * @memberof FormModel
	 * @param {blob} file - ファイル
	 * @param {integer} userId - ユーザーID
	 * @return {undefined}
	 */
	uploadPhoto(file, userId) {
		if (this._post) return
		console.log(`FormModel.uploadPhoto`)
		Common.showLoading()
		this._post = true
		let formData = new FormData()
		formData.append('photofile', file)
		formData.append('user_id', userId)
		const url = window.const.API_URL.UPLOAD_PHOTO
		let params = {}
		params['data'] = formData
		params['method'] = 'POST'
		params['dataType'] = 'json'
		params['url'] = url
		params['processData'] = false
		params['contentType'] = false
		console.log(JSON.stringify(params))
		this._jqXHR = $.ajax(params).always(this.onUploadPhotoComplete.bind(this))
	}

	/**
	 * 画像アップロードajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onUploadPhotoComplete(data, textStatus) {
		console.log('FormModel.onUploadPhotoComplete')
		Common.hideLoading()
		this._data = data.result
		this._code = data.code
		this._message = data.message
		console.log(`code: ${this._code} / message: ${this._message}`)
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false
				Common.showErrorModal(this._message)
			} else {
				this._post = false
				this._listener.trigger('uploadPhotoComplete')
			}
		} else {
			this._post = false
			this._code = 9999
			this._message = '通信エラーが発生しました。<br>再度お試しください。'
			Common.showErrorModal(this._message)
			console.log(`ajax error (onUploadPhotoComplete): ${textStatus}`)
		}
		this._jqXHR = null
	}

	/**
	 * listener on
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn)
	}

	/**
	 * listner off
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn)
	}
}
